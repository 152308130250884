/* eslint-disable */
import React from 'react'
import AuthSignUp from "./AuthSingUp";
import { useNavigate } from 'react-router-dom';

function SignUp() {

    const navigate = useNavigate()

    return (
        <div className='mt-[20px] lg:mt-[50px]'>
            <AuthSignUp />

            <div className='flex justify-center lg:justify-start text-black gap-1 mt-8'>
                <p className='opacity-60'>Already have an account?{' '}
                </p>
                <span
                    className='text-primary cursor-pointer'
                    role='button'
                    onClick={() => navigate('/login')}
                >
                    Sign in
                </span>
            </div>
        </div>
    )
}

export default SignUp