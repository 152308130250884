import React, { useEffect } from 'react';
import { FormControl, MenuItem, Select, ListItemIcon, Avatar, ListSubheader } from '@mui/material';
import { IconCheck } from '@tabler/icons';
import { useDispatch, useSelector } from '../../../../store';
import { StorageManager } from '../../../../utils/storageManager';
import { updateUserSettings } from '../../../../store/slices/user';

const Organization = ({ organizations, collapsed }) => {
    const dispatch = useDispatch();
    const { active_org } = useSelector(state => state.account)
    const [selectedValue, setSelectedValue] = React.useState(active_org?.org_id || '');

    useEffect(() => {
        setSelectedValue(active_org?.org_id || '')
    }, [active_org])

    const handleChange = async (event) => {
        const selectedOrgId = event.target.value;
        setSelectedValue(selectedOrgId);

        const selectedOrg = organizations.find((org) => org?.org_id === selectedOrgId);

        const cachedOrg = new StorageManager('ACTIVE_ORG', 'OTHERS').getStorage();

        if (cachedOrg && cachedOrg?.org_id !== selectedOrg?.org_id) {

            const orgDataToSave = {
                org_id: selectedOrgId,
                org_name: selectedOrg?.org_name,
            }
            new StorageManager('ACTIVE_ORG', 'OTHERS', false, orgDataToSave).setStorage()
            // await dispatch(updateUserSettings(
            //     { startup_org_id: selectedOrgId }
            // ))
            window.location.reload();
        }
    };

    return (
        <FormControl sx={{ minWidth: '100%' }}>
            {!collapsed ? (
                <Select
                    name="provider"
                    value={selectedValue}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    renderValue={(selected) => {
                        const selectedOrg = organizations?.find((org) => org?.org_id === selected);
                        return selectedOrg ? (
                            <div className="flex items-center">
                                <Avatar className="bg-[#169cd9] w-7 h-7 text-base mr-2 text-white">
                                    {selectedOrg?.org_name?.charAt(0).toUpperCase()}
                                </Avatar>
                                <span>{selectedOrg?.org_name}</span>
                            </div>
                        ) : (
                            <span>Select an organization</span>
                        );
                    }}
                >
                    <ListSubheader>
                        <p className='text-[12px] font-semibold'>ORGANIZATIONS</p>
                    </ListSubheader>
                    {organizations && organizations?.map((org) => (
                        <MenuItem
                            key={org.org_id}
                            value={org.org_id}
                            className={`flex items-center ${selectedValue !== org?.org_id && 'pl-[40px]'}`}
                        >
                            {selectedValue === org?.org_id && (
                                <div className="mr-2">
                                    <IconCheck className="w-4 text-black" />
                                </div>
                            )}
                            <div className="flex items-center">
                                <ListItemIcon className="mr-1">
                                    <Avatar className="bg-[#169cd9] w-7 h-7 text-base text-white">
                                        {org?.org_name.charAt(0).toUpperCase()}
                                    </Avatar>
                                </ListItemIcon>
                                <span>{org?.org_name}</span>
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                <Select
                    name="provider"
                    value={selectedValue}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{
                        width: '150px',
                        border: 'none',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                    renderValue={(selected) => {
                        const selectedOrg = organizations?.find((org) => org?.org_id === selected);
                        return selectedOrg ? (
                            <div className="flex items-center">
                                <Avatar className="bg-[#169cd9] w-7 h-7 text-base text-white">
                                    {selectedOrg?.org_name?.charAt(0)?.toUpperCase()}
                                </Avatar>
                            </div>
                        ) : (
                            <Avatar className="bg-[#169cd9] w-7 h-7 text-base text-white">
                                U
                            </Avatar>
                        );
                    }}
                >
                    {organizations && organizations?.map((org) => (
                        <MenuItem
                            key={org?.org_id}
                            value={org?.org_id}
                            className={`flex items-center ${selectedValue !== org?.org_id && 'pl-[40px]'}`}
                            sx={{ width: '230px' }}
                        >
                            {selectedValue === org?.org_id && (
                                <div className="mr-2">
                                    <IconCheck className="w-4 text-black" />
                                </div>
                            )}
                            <ListItemIcon className="mr-2">
                                <Avatar className="bg-[#169cd9] w-7 h-7 text-base text-white">
                                    {org?.org_name?.charAt(0)?.toUpperCase()}
                                </Avatar>
                            </ListItemIcon>
                            <span>{org?.org_name}</span>

                        </MenuItem>
                    ))}
                </Select>
            )}
        </FormControl>
    );
};

export default Organization;
