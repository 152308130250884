
/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../../utils/axios.js';
import { dispatch } from '../../index';
import { handleError, handleSuccess } from '../../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    organizations: null,
    singleOrganization: null,
    loading: false,
    saveLoading: false,
    totalPages: null,
    totalCount: null,
};

const slice = createSlice({
    name: 'adminOrganization',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isSaveLoading(state, action) {
            state.saveLoading = action.payload;
        },

        setOrganizationDataSuccess(state, action) {
            state.organizations = action.payload?.data;
            state.totalCount = action.payload?.totalCount;
            state.totalPages = action.payload?.totalPages;
        },

        setSingleOrganizationDataSuccess(state, action) {
            state.singleOrganization = action.payload;
        },

        updateOrganizationDataSuccess(state, action) {
            const index = state.organizations.findIndex(organization => organization._id === action.payload.id);
            const organizationData = state.organizations[index];
            const newData = { ...organizationData, ...action.payload.data };
            state.organizations[index] = newData
        },

        createOrganizationDataSuccess(state, action) {
            state.organizations.push(action.payload);
        },

        deleteOrganizationDataSuccess(state, action) {
            const newOrganizations = state.organizations.filter(organization => organization._id !== action.payload.id);
            state.organizations = newOrganizations;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllOrganizations({ page, limit, search }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/organization?search=${search}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setOrganizationDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}
export function getSingleOrganization({ id }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/organization/${id}`);
            dispatch(slice.actions.setSingleOrganizationDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}

export function createOrganization(data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.post(`/admin/organization`, data);
            dispatch(slice.actions.createOrganizationDataSuccess(response.data))

            handleSuccess(response, "Organization created successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function updateOrganization(id, data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.put(`/admin/organization/${id}`, data);

            dispatch(slice.actions.updateOrganizationDataSuccess(
                { data, id }
            ))
            handleSuccess(response, "Organization updated successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function deleteOrganization(id) {
    return async () => {
        try {
            const response = await axios.delete(`/admin/organization/${id}`);
            dispatch(slice.actions.deleteOrganizationDataSuccess(
                { id }
            ))
            handleSuccess(response, "Organization deleted successfully", dispatch);

            return response.data?.message;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
    }
}
