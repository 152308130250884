/* eslint-disable */
import React from 'react'
import AuthLogin from "./AuthLogin";
import { useNavigate } from 'react-router-dom';

function SignIn(props) {

    const navigate = useNavigate()

    return (
        <div className='mt-[20px] lg:mt-[50px]'>
            <AuthLogin />

            <div className='flex justify-center lg:justify-start text-black gap-1 mt-8'>
                <p className='opacity-60'>Don't have an account?{' '}
                </p>
                <span
                    className='text-primary cursor-pointer'
                    role='button'
                    onClick={() => navigate('/sign-up')}
                >
                    Sign up
                </span>
            </div>
        </div>

    )
}

export default SignIn
