import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    ClickAwayListener,
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import NavItem from '../NavItem';
import Transitions from '../../../../ui-elements/Transitions';

import { useSelector } from '../../../../store';
import LAYOUT_CONST from '../../../../constant';
import useConfig from '../../../../hooks/useConfig';

// assets
import { IconChevronDown, IconChevronRight, IconChevronUp } from '@tabler/icons';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { color } from '@mui/system';
import { icons } from '../../../../menu-items';

// mini-menu - wrapper
const PopperStyledMini = styled(Popper)(({ theme }) => ({
    overflow: 'visible',
    zIndex: 1202,
    minWidth: 180,
    '&:before': {
        content: '""',
        backgroundColor: theme.palette.background.paper,
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 120,
        borderLeft: `1px solid ${theme.palette.border.main}`,
        borderBottom: `1px solid ${theme.palette.border.main}`
    }
}));

// horizontal-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
    overflow: 'visible',
    zIndex: 1202,
    minWidth: 180,
    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 34,
        left: -5,
        width: 12,
        height: 12,
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 120,
        borderWidth: '6px',
        borderStyle: 'solid',
        borderColor: `transparent transparent ${theme.palette.background.paper}  ${theme.palette.background.paper}`
    },
    '&[data-popper-placement="left-start"]:before': {
        left: 'auto',
        right: -5,
        borderColor: `${theme.palette.background.paper}  ${theme.palette.background.paper} transparent transparent`
    },
    '&[data-popper-placement="left-end"]:before': {
        top: 'auto',
        bottom: 15,
        left: 'auto',
        right: -5,
        borderColor: `${theme.palette.background.paper}  ${theme.palette.background.paper} transparent transparent`
    },
    '&[data-popper-placement="right-end"]:before': {
        top: 'auto',
        bottom: 15
    }
}));

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level, parentId }) => {
    const theme = useTheme();
    const palette = theme.palette;
    const mode = theme.palette.mode;

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const { layout, borderRadius } = useConfig();
    const { drawerOpen } = useSelector((state) => state.menu);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickMini = (event) => {
        setAnchorEl(null);
        if (drawerOpen) {
            setOpen(!open);
            setSelected(!selected ? menu.id : null);
        } else {
            setAnchorEl(event?.currentTarget);
        }
    };

    const handleHover = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClosePopper = () => {
        setOpen(false);
        setSelected(null);
        setAnchorEl(null);
    };

    const openMini = Boolean(anchorEl);
    const { pathname } = useLocation();

    const checkOpenForParent = (child, id) => {
        child.forEach((item) => {
            if (item.url === pathname) {
                setOpen(true);
                setSelected(id);
            }
        });
    };

    useEffect(() => {
        if (openMini) setAnchorEl(null);

        if (menu.children) {
            // Track if any matching URL is found
            let matchFound = false;

            menu.children.forEach((item) => {
                if (item.children?.length) {
                    checkOpenForParent(item.children, menu.id);
                }
                if (pathname.includes(item.url)) {
                    console.log("Came here", pathname, item.url, item, menu.id);
                    setSelected(menu.id);
                    setOpen(true);
                    matchFound = true;
                }
            });

            // If no matches, close the menu
            if (!matchFound) {
                console.log("No match found for pathname in menu items", pathname);
                setSelected(null);
                setOpen(false);
            }
        }
    }, [pathname, menu.children]);

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} parentId={parentId} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} parentId={parentId} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const isSelected = selected === menu.id;

    const Icon = menu.icon;
    const NewIcon = icons[Icon]
    const menuIcon = menu.icon ? (
        <NewIcon
            strokeWidth={2}
            size={drawerOpen ? '20px' : '25px'}
            style={{
                color: mode === 'light' ? (isSelected ? palette.primary.main : palette.text.primary)
                    : (isSelected ? palette.text.light : palette.text.main)
            }}
        />
    ) : (
        <FiberManualRecordIcon
            sx={{
                color: isSelected ? theme.palette.primary.main : theme.palette.text.primary,
                width: isSelected ? 8 : 6,
                height: isSelected ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    const collapseIcon = drawerOpen ? (
        <IconChevronUp stroke={2} size="16px" style={{ marginTop: 'auto', marginBottom: 'auto', color: theme.palette.text.primary }} />
    ) : (
        <IconChevronRight stroke={2} size="16px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    );

    const popperId = openMini ? `collapse-pop-${menu.id}` : undefined;

    return (
        <>
            {layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
                <>
                    <ListItemButton
                        className={menu?.class}
                        sx={{
                            zIndex: 1201,
                            borderRadius: `${borderRadius}px`,
                            mb: 0.5,
                            pl: drawerOpen ? `${level * 20}px` : 1.25,
                            ...(drawerOpen &&
                                level === 1 &&
                                theme.palette.mode !== 'dark' && {
                                '&:hover': {
                                    background: theme.palette.secondary.main
                                },
                                '&.Mui-selected': {
                                    background: `${theme.palette.secondary.main} !important`,
                                    color: "#fff",
                                    '&:hover': {
                                        color: theme.palette.text.primary,
                                        background: theme.palette.secondary.main
                                    }
                                }
                            }),
                            ...(drawerOpen &&
                                level === 1 &&
                                theme.palette.mode === 'dark' && {
                                color: theme.palette.text.main,
                                '&:hover': {
                                    background: theme.palette.secondary.main,
                                    color: theme.palette.text.primary
                                },
                                '&.Mui-selected': {
                                    background: theme.palette.background.gradientSecondaryDark,
                                    color: theme.palette.text.primary,
                                    '&:hover': {
                                        color: theme.palette.text.primary,
                                        background: theme.palette.background.gradientSecondaryDark
                                    }
                                }
                            }),
                            ...((!drawerOpen || level !== 1) && {
                                py: level === 1 ? 0 : 1,
                                '&:hover': {
                                    bgcolor: `${!drawerOpen ? "transparent" : theme.palette.secondary.main}`
                                },
                                '&.Mui-selected': {
                                    backgroundColor: level !== 1 ? (mode === "dark" ? theme.palette.background.main : theme.palette.secondary.main) : 'transparent',
                                    color: theme.palette.text.primary,
                                    '&:hover': {
                                        color: theme.palette.text.primary,
                                        backgroundColor: level !== 1 ? (mode === "dark" ? theme.palette.background.main : theme.palette.secondary.main) : 'transparent',
                                    }
                                }
                            })
                        }}
                        selected={selected === menu.id}
                        {...(!drawerOpen && { onMouseEnter: handleClickMini, onMouseLeave: handleClosePopper })}
                        onClick={handleClickMini}
                    >
                        {menuIcon && (
                            <ListItemIcon
                                sx={{
                                    minWidth: level === 1 ? 36 : 18,
                                    color: isSelected ? theme.palette.primary.main : theme.palette.text.primary,
                                    ...(!drawerOpen &&
                                        level === 1 && {
                                        borderRadius: `50px`,
                                        width: 46,
                                        height: 46,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            bgcolor: theme.palette.secondary.main,
                                        },
                                        ...(isSelected && {
                                            bgcolor: mode === 'dark' ? theme.palette.background.main : theme.palette.secondary.main,
                                            '&:hover': {
                                                bgcolor: mode === 'dark' ? theme.palette.background.main : theme.palette.secondary.main,
                                            }
                                        })
                                    })
                                }}
                            >
                                {menuIcon}
                            </ListItemIcon>
                        )}
                        {(drawerOpen || (!drawerOpen && level !== 1)) && (
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={selected === menu.id ? 'h5' : 'body1'}

                                        color={drawerOpen && mode === 'light' ?
                                            (isSelected ? theme.palette.primary.main : theme.palette.text.primary)
                                            :
                                            (isSelected ? theme.palette.text.light : theme.palette.text.light)
                                        }
                                    >
                                        {menu.title}
                                    </Typography>
                                }
                                secondary={
                                    menu.caption && (
                                        <Typography
                                            variant="caption"
                                            sx={{ ...theme.typography.subMenuCaption }}
                                            display="block"
                                            gutterBottom
                                        >
                                            {menu.caption}
                                        </Typography>
                                    )
                                }
                            />
                        )}

                        {openMini || open ? (
                            collapseIcon
                        ) : (
                            <IconChevronDown stroke={1.5} size="16px"
                                style={{ marginTop: 'auto', marginBottom: 'auto', color: theme.palette.text.primary }}
                            />
                        )}

                        {!drawerOpen && (
                            <PopperStyledMini
                                open={openMini}
                                anchorEl={anchorEl}
                                placement="right-start"
                                style={{
                                    zIndex: 2001,
                                }}
                                modifiers={[
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [-12, 0]
                                        }
                                    }
                                ]}
                            >
                                {({ TransitionProps }) => (
                                    <Transitions in={openMini} {...TransitionProps}>
                                        <Paper
                                            sx={{
                                                overflow: 'hidden',
                                                mt: 0,
                                                boxShadow: theme.shadows[8],
                                                backgroundImage: 'none',
                                                padding: '12px'
                                            }}
                                        >
                                            <ClickAwayListener onClickAway={handleClosePopper}>
                                                <Box>{menus}</Box>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Transitions>
                                )}
                            </PopperStyledMini>
                        )}
                    </ListItemButton>
                    {drawerOpen && (
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {open && (
                                <List
                                    component="div"
                                    disablePadding
                                    sx={{
                                        position: 'relative',
                                        '&:after': {
                                            content: "''",
                                            position: 'absolute',
                                            left: '32px',
                                            top: 0,
                                            height: '100%',
                                            width: '1px',
                                            opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                                            background: theme.palette.secondary.main
                                        }
                                    }}
                                >
                                    {menus}
                                </List>
                            )}
                        </Collapse>
                    )}
                </>
            ) : null}

            {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? (
                <>
                    <ListItemButton
                        id={`boundary-${popperId}`}
                        disableRipple
                        selected={selected === menu.id}
                        onMouseEnter={handleHover}
                        onMouseLeave={handleClosePopper}
                        onClick={handleHover}
                        aria-describedby={popperId}
                    >
                        {menuIcon && <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>}
                        <ListItemText
                            primary={
                                <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                                    {menu.title}
                                </Typography>
                            }
                        />
                        {openMini ? <IconChevronRight stroke={1.5} size="16px" /> : <IconChevronDown stroke={1.5} size="16px" />}

                        {anchorEl && (
                            <PopperStyled
                                id={popperId}
                                open={openMini}
                                anchorEl={anchorEl}
                                placement="right-start"
                                style={{
                                    zIndex: 2001
                                }}
                                modifiers={[
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [-10, 0]
                                        }
                                    }
                                ]}
                            >
                                {({ TransitionProps }) => (
                                    <Transitions in={openMini} {...TransitionProps}>
                                        <Paper
                                            sx={{
                                                overflow: 'hidden',
                                                mt: 1.5,
                                                py: 0.5,
                                                boxShadow: theme.shadows[8],
                                                backgroundImage: 'none'
                                            }}
                                        >
                                            <ClickAwayListener onClickAway={handleClosePopper}>
                                                <Box>{menus}</Box>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Transitions>
                                )}
                            </PopperStyled>
                        )}
                    </ListItemButton>
                </>
            ) : null}
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number,
    parentId: PropTypes.string
};

export default NavCollapse;
