
/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';
import { dispatch } from '../../index';
import { handleError, handleSuccess } from '../../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    orgAgentTemplates: null,
    singleOrgAgentTemplate: null,
    loading: false,
    saveLoading: false,
    totalPages: null,
    totalCount: null,
};

const slice = createSlice({
    name: 'adminOrgAgentTemplate',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isSaveLoading(state, action) {
            state.saveLoading = action.payload;
        },

        setOrgAgentTemplateDataSuccess(state, action) {
            state.orgAgentTemplates = action.payload?.data;
            state.totalCount = action.payload?.totalCount;
            state.totalPages = action.payload?.totalPages;
        },

        setOrgSingleAgentTemplateDataSuccess(state, action) {
            state.singleOrgAgentTemplate = action.payload;
        },

        updateOrgAgentTemplateDataSuccess(state, action) {
            const index = state.orgAgentTemplates.findIndex(orgAgentTemplate => orgAgentTemplate._id === action.payload.id);
            const orgAgentTemplateData = state.orgAgentTemplates[index];
            const newData = { ...orgAgentTemplateData, ...action.payload.data };
            state.orgAgentTemplates[index] = newData
        },

        createOrgAgentTemplateDataSuccess(state, action) {
            state.orgAgentTemplates.push(action.payload);
        },

        deleteOrgAgentTemplateDataSuccess(state, action) {
            const newAgentTemplates = state.orgAgentTemplates.filter(orgAgentTemplate => orgAgentTemplate._id !== action.payload.id);
            state.orgAgentTemplates = newAgentTemplates;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllOrgAgentTemplates({ page, limit, search }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/org-agent-template?search=${search}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setOrgAgentTemplateDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}
export function getSingleOrgAgentTemplate({ id }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/org-agent-template/${id}`);
            dispatch(slice.actions.setOrgSingleAgentTemplateDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}

export function createOrgAgentTemplate(data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.post(`/admin/org-agent-template`, data);
            dispatch(slice.actions.createOrgAgentTemplateDataSuccess(response.data))

            handleSuccess(response, "Agent Template created successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function updateOrgAgentTemplate(id, data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.put(`/admin/org-agent-template/${id}`, data);

            dispatch(slice.actions.updateOrgAgentTemplateDataSuccess(
                { data, id }
            ))
            handleSuccess(response, "Agent Template updated successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function deleteOrgAgentTemplate(id) {
    return async () => {
        try {
            const response = await axios.delete(`/admin/org-agent-template/${id}`);
            dispatch(slice.actions.deleteOrgAgentTemplateDataSuccess(
                { id }
            ))
            handleSuccess(response, "Agent Template deleted successfully", dispatch);

            return response.data?.message;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
    }
}
