/* eslint-disable */
import { lazy } from 'react';

// project imports
import Loadable from '../ui-elements/Loadable';
import MainLayout from '../layout/MainLayout';

// GUARDS IMPORT
import AuthGuard from '../utils/guards/AuthGuard';
import { NumberBuyGuard, NumbersReadGuard, NumbersSettingGuard } from '../utils/guards/NumbersGuard';
import { ViewAgentsGuard } from '../utils/guards/AssistantGuards';
import { CallHistoryReadGuard } from '../utils/guards/CallHistoryGuard';
import { ApiKeysReadGuard } from '../utils/guards/ApiKeyGuard';
import NavMotion from '../layout/NavMotion';
import { ObcReadGuard, ObcWriteGuard } from '../utils/guards/OutboundGuard';

const PhoneNumberList = Loadable(lazy(() => import('../views/Admin/PhoneNumber')));
const PhoneNumberOperation = Loadable(lazy(() => import('../views/Admin/PhoneNumber/operation')));
const TakenNumberList = Loadable(lazy(() => import('../views/Admin/TakenNumber')));
const TakenNumberOperation = Loadable(lazy(() => import('../views/Admin/TakenNumber/operation')));
const AgentTemplateList = Loadable(lazy(() => import('../views/Admin/AgentTemplate')));
const AgentTemplateOperation = Loadable(lazy(() => import('../views/Admin/AgentTemplate/operation')));
const AgentList = Loadable(lazy(() => import('../views/Admin/Agent')));
const OrganizationList = Loadable(lazy(() => import('../views/Admin/Organization')));
const OrganizationOperation = Loadable(lazy(() => import('../views/Admin/Organization/operation')));
const OrgMemberList = Loadable(lazy(() => import('../views/Admin/OrgMember')));
const OrgMemberOperation = Loadable(lazy(() => import('../views/Admin/OrgMember/operation')));
const UserList = Loadable(lazy(() => import('../views/Admin/User')));
const UserOperation = Loadable(lazy(() => import('../views/Admin/User/operation')));
const ContactUsList = Loadable(lazy(() => import('../views/Admin/Miscellaneous/contactUsList')));
const OrgAgentTemplateList = Loadable(lazy(() => import('../views/Admin/OrgAgentTemplate')));
const OrgAgentTemplateListOperation = Loadable(lazy(() => import('../views/Admin/OrgAgentTemplate/operation')));


const AdminRoutes = {
    path: '/dashboard/admin/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: "user",
            element: (
                <NavMotion>
                    <UserList />
                </NavMotion>
            )
        },
        {
            path: "user/operation/:id",
            element: (
                <NavMotion>
                    <UserOperation />
                </NavMotion>
            )
        },
        {
            path: "phone-number",
            element: (
                <NavMotion>
                    <PhoneNumberList />
                </NavMotion>
            )
        },
        {
            path: "phone-number/operation",
            element: (
                <NavMotion>
                    <PhoneNumberOperation />
                </NavMotion>
            )
        },
        {
            path: "phone-number/operation/:id",
            element: (
                <NavMotion>
                    <PhoneNumberOperation />
                </NavMotion>
            )
        },
        {
            path: "taken-number",
            element: (
                <NavMotion>
                    <TakenNumberList />
                </NavMotion>
            )
        },
        {
            path: "taken-number/operation",
            element: (
                <NavMotion>
                    <TakenNumberOperation />
                </NavMotion>
            )
        },
        {
            path: "taken-number/operation/:id",
            element: (
                <NavMotion>
                    <TakenNumberOperation />
                </NavMotion>
            )
        },
        {
            path: "agent-template",
            element: (
                <NavMotion>
                    <AgentTemplateList />
                </NavMotion>
            )
        },
        {
            path: "agent-template/operation",
            element: (
                <NavMotion>
                    <AgentTemplateOperation />
                </NavMotion>
            )
        },
        {
            path: "agent-template/operation/:id",
            element: (
                <NavMotion>
                    <AgentTemplateOperation />
                </NavMotion>
            )
        },
        {
            path: "org-agent-template",
            element: (
                <NavMotion>
                    <OrgAgentTemplateList />
                </NavMotion>
            )
        },
        {
            path: "org-agent-template/operation",
            element: (
                <NavMotion>
                    <OrgAgentTemplateListOperation />
                </NavMotion>
            )
        },
        {
            path: "org-agent-template/operation/:id",
            element: (
                <NavMotion>
                    <OrgAgentTemplateListOperation />
                </NavMotion>
            )
        },
        {
            path: "agent",
            element: (
                <NavMotion>
                    <AgentList />
                </NavMotion>
            )
        },
        {
            path: "organization",
            element: (
                <NavMotion>
                    <OrganizationList />
                </NavMotion>
            )
        },
        {
            path: "organization/operation/:id",
            element: (
                <NavMotion>
                    <OrganizationOperation />
                </NavMotion>
            )
        },
        {
            path: "org-member",
            element: (
                <NavMotion>
                    <OrgMemberList />
                </NavMotion>
            )
        },
        {
            path: "org-member/operation",
            element: (
                <NavMotion>
                    <OrgMemberOperation />
                </NavMotion>
            )
        },
        {
            path: "org-member/operation/:id",
            element: (
                <NavMotion>
                    <OrgMemberOperation />
                </NavMotion>
            )
        },
        {
            path: "miscellaneous",
            children: [
                {
                    path: "contact-us",
                    element: (
                        <NavMotion>
                            <ContactUsList />
                        </NavMotion>
                    )
                },
            ]
        },
    ]
};

export default AdminRoutes