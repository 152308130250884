
/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';
import { dispatch } from '../../index';
import { handleError, handleSuccess } from '../../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    takenNumbers: null,
    singleTakenNumber: null,
    loading: false,
    saveLoading: false,
    totalPages: null,
    totalCount: null,
};

const slice = createSlice({
    name: 'adminTakenNumber',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isSaveLoading(state, action) {
            state.saveLoading = action.payload;
        },

        setTakenNumberDataSuccess(state, action) {
            state.takenNumbers = action.payload?.data;
            state.totalCount = action.payload?.totalCount;
            state.totalPages = action.payload?.totalPages;
        },

        setSingleTakenNumberDataSuccess(state, action) {
            state.singleTakenNumber = action.payload;
        },

        updateTakenNumberDataSuccess(state, action) {
            const index = state.takenNumbers.findIndex(phoneNumber => phoneNumber._id === action.payload.id);
            const phoneNumberData = state.takenNumbers[index];
            const newData = { ...phoneNumberData, ...action.payload.data };
            state.takenNumbers[index] = newData
        },

        createTakenNumberDataSuccess(state, action) {
            state.takenNumbers.push(action.payload);
        },

        deleteTakenNumberDataSuccess(state, action) {
            const newTakenNumbers = state.takenNumbers.filter(phoneNumber => phoneNumber._id !== action.payload.id);
            state.takenNumbers = newTakenNumbers;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllTakenNumbers({ page, limit, search }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/taken-number?search=${search}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setTakenNumberDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}
export function getSingleTakenNumber({ id }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/taken-number/${id}`);
            dispatch(slice.actions.setSingleTakenNumberDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}

export function createTakenNumber(data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.post(`/admin/taken-number`, data);
            dispatch(slice.actions.createTakenNumberDataSuccess(response.data))

            handleSuccess(response, "Phone Number created successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function updateTakenNumber(id, data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.put(`/admin/taken-number/${id}`, data);

            dispatch(slice.actions.updateTakenNumberDataSuccess(
                { data, id }
            ))
            handleSuccess(response, "Phone Number updated successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function deleteTakenNumber(id) {
    return async () => {
        try {
            const response = await axios.delete(`/admin/taken-number/${id}`);
            dispatch(slice.actions.deleteTakenNumberDataSuccess(
                { id }
            ))
            handleSuccess(response, "Phone Number deleted successfully", dispatch);

            return response.data?.message;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
    }
}
