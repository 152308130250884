/* eslint-disable */
import React, { useEffect } from 'react';
import { motion } from 'framer-motion'
import SignIn from '../../components/auth/Singnin';
import Styles from '../../../styles/landingpage.module.scss';
import AuthFormStyle from "../../../styles/dialog.module.scss"

import Header from '../../../ui-elements/header';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import SignUp from '../../components/auth/SignUp';
import ForgetPassword from '../../components/auth/ForgetPassword';
import { useSelector } from '../../../store';
import TwoFaAuthPage from '../../components/auth/TwoFaAuthPage';
import { StorageManager } from '../../../utils/storageManager';

function index() {
    const { jwtToken } = useAuth();
    const navigate = useNavigate();
    const { isLoggedIn, data: userData, auth_loading } = useSelector((state) => state.account)
    const { menuItems, loadingMenuItems } = useSelector(state => state.dashboard)

    const pathname = window.location.pathname;

    useEffect(() => {
        const token = new StorageManager('ACCESS_TOKEN', 'AUTH', true).getStorage();
        if (isLoggedIn && token) {
            if (userData?.startup_tab_id) {
                //find the link from the menuItems.children where id is userData?.startup_tab_id
                const item = menuItems?.children?.find(item => item.id === userData?.startup_tab_id);
                if (item) {
                    navigate(item?.url);
                    return
                }
            }
            else {
                window.location.href = '/dashboard/quick-start'
            }
        }
    }, [isLoggedIn, jwtToken, userData, menuItems]);


    return (
        <div className={Styles.container}>
            <Header />

            <div className={`${AuthFormStyle.loginform} relative`}>

                {pathname === '/login' ?
                    <SignIn />
                    :
                    pathname === '/sign-up' ?
                        <SignUp />
                        :
                        pathname === '/forgot-password' ?
                            <ForgetPassword />
                            :
                            pathname === '/auth/two-factor' ?
                                <TwoFaAuthPage />
                                :
                                null
                }
                <motion.div
                    className="absolute top-[23rem] left-[-22rem] hidden lg:block"
                    style={{
                        zIndex: 2000,
                    }}
                    initial={{ y: 0 }} 
                    animate={{ y: [0, -20, 0] }} // Up and down animation
                    transition={{
                        repeat: Infinity, 
                        duration: 3, // Time for one complete oscillation
                        ease: "easeInOut", // Smooth easing
                    }}
                >
                    <img
                        src={
                            "https://res.cloudinary.com/dgcicwap9/image/upload/f_webp,fl_awebp,q_auto/v1728891833/Dashboard/ap9amdcxj5ngiixx3xg6"
                        }
                        alt="authPageImage"
                        className="w-[500px]"
                        style={{ objectFit: "cover" }}
                    />
                </motion.div>

            </div>
        </div>
    );
}

export default index;
