import { Autocomplete, MenuItem, Select, TextField, } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles"

export const MuiSelect = ({
    name, value, items,
    handleChange, disabled,
    placeholder,
    sx = {},
    emptyContent = "No options"
}) => {

    return (
        <Select
            name={name}
            value={value || ""}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) => {
                if (selected === "") {
                    return <p>{placeholder}</p>;
                }
                const selectedOption = items?.find(option => option?.value === selected);
                return (
                    <span className="truncate">
                        {selectedOption?.renderValue || selectedOption?.label || placeholder}
                    </span>
                );
                // return selectedOption?.renderValue || selectedOption?.label || placeholder;
            }}
            disabled={disabled}
            sx={{
                ...sx,
                "& .MuiSelect-select": {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    display: "block",
                },
            }}
        >
            {items && items?.length > 0 ? (
                items?.map((item) => (
                    <MenuItem key={item?.value} value={item?.value} disabled={item?.disabled}>
                        {item?.label}
                    </MenuItem>
                ))
            ) : (
                <MenuItem disabled>
                    <p>{emptyContent}</p>
                </MenuItem>
            )}
        </Select>
    )
}

MuiSelect.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
};


export const MuiSelectDark = ({
    name, value, items,
    handleChange, disabled,
    placeholder,
    sx = {}
}) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <Select
            name={name}
            value={value || ""}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) => {
                if (selected === "") {
                    return <p>{placeholder}</p>;
                }
                const selectedOption = items?.find(option => option?.value === selected);
                return selectedOption?.label || placeholder;
            }}
            disabled={disabled}
            sx={{
                background: mode === 'dark' && `${theme.palette.background.gradientDark} !important`,
                '& .MuiOutlinedInput-input': {
                    background: mode === 'dark' && `transparent !important`,
                },
                ...sx
            }}
        >
            {items && items?.length > 0 ? (
                items?.map((item) => (
                    <MenuItem key={item?.value} value={item?.value} disabled={item?.disabled}>
                        {item?.label}
                    </MenuItem>
                ))
            ) : (
                <MenuItem disabled>
                    <p>No options</p>
                </MenuItem>
            )}
        </Select>
    )
}

MuiSelectDark.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
};


export const MuiAutocomplete = ({
    name,
    value,
    items,
    handleChange,
    disabled,
    placeholder,
    label,
    getOptionLabel = (option) => option?.label || '',
    renderOption = (props, option) => (
        <li {...props}>
            <div>{option.label}</div>
        </li>
    ),
}) => {
    // Find the matching item for the Autocomplete input display
    const selectedItem = items.find(item => item.value === value) || null;

    return (
        <Autocomplete
            name={name}
            value={selectedItem} // Display the matched item (with label)
            onChange={(event, newValue) => handleChange(event, newValue ? newValue.value : '')} // Pass only the `value`
            options={items || []}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
                <TextField {...params} label={label || placeholder} fullWidth />
            )}
            renderOption={renderOption}
            disabled={disabled}
            isOptionEqualToValue={(option, value) => option?.value === value} // Match only by `value`
            noOptionsText="No options available"
        />
    );
};

MuiAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
        })
    ).isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    getOptionLabel: PropTypes.func,
    renderOption: PropTypes.func,
};
