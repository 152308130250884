
/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';
import { dispatch } from '../../index';
import { handleError, } from '../../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    contactUsData: null,
    singleContactUsData: null,
    loading: false,
    totalPages: null,
    totalCount: null,
};

const slice = createSlice({
    name: 'adminMiscellaneous',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        setContactUsDataSuccess(state, action) {
            state.contactUsData = action.payload?.data;
            state.totalCount = action.payload?.totalCount;
            state.totalPages = action.payload?.totalPages;
        },

        setSingleContactUsDataSuccess(state, action) {
            state.singleUser = action.payload;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllContactUsData({ page, limit, search }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/miscellaneous/contact-us?search=${search}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setContactUsDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}
