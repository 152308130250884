import { DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { ClickableCancelIcon } from "../../../ui-elements/IconifyIcon"
import { Icon } from "@iconify/react/dist/iconify.js"
import { LoadingButton } from "@mui/lab"
import { closeConfirmationModal } from "../../../store/slices/confirmationModal"
import { useDispatch } from "../../../store"

export const NullSubscription = ({ onClose = () => { }, onSubmit, triggerGlobalClose = true }) => {

    const dispatch = useDispatch();

    const handleSubmit = async () => {
        await onSubmit();
        handleClose();
    };

    const handleClose = async () => {
        onClose();

        if (triggerGlobalClose) {
            dispatch(closeConfirmationModal());
        }
    };

    return (
        <>
            <DialogTitle id="responsive-dialog-title" sx={{ textAlign: 'right', p: 1 }} className='relative'>
                <ClickableCancelIcon
                    style={{
                        position: 'absolute',
                        right: 14,
                        top: 14,
                    }}
                    iconStyle={{
                        height: '24px',
                        width: '24px',
                    }}
                    handleClick={handleClose}
                />
            </DialogTitle>
            <DialogContent
                sx={{
                    textAlign: 'center',
                    px: 4,
                    pb: 2,
                    marginTop: '-50px',
                }}
                className='flex flex-col items-center justify-center'
            >
                <div className='bg-primary rounded-full w-[70px] h-[70px] flex items-center justify-center'>
                    <Icon icon="uil:exclamation" className='text-[80px] text-white' />
                </div>

                <Typography sx={{ mt: 4, mb: 2 }} className='text-xl font-semibold text-primary'>
                    Subscription Required!
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }} className='text-tertiary'>
                    You don't have any active subscription for this <br />{' '}
                    service. Please subscribe to one of our<br />{' '}
                    plans to access this feature.
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
                <LoadingButton
                    variant="contained"
                    onClick={handleSubmit}
                    className='px-5 sm:px-10 text-xs sm:text-base'
                >
                    View Plans
                </LoadingButton>
            </DialogActions>
        </>
    )
}