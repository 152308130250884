import PropTypes from 'prop-types';
import React from 'react';
import Styles from "../../../styles/login.module.scss";

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

import useAuth from '../../../hooks/useAuth';

import googleIcon from '../../../assets/images/google.png'
import { LoadingButton } from '@mui/lab';
import { openSnackbar } from '../../../store/slices/snackbar';
import { useDispatch, useSelector } from '../../../store';
import { CheckCircleOutline } from '@mui/icons-material';
import { getFirebaseErrorMessage } from './getFirebaseErrorMessage';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate } from 'react-router';
import { IS_AUTHENTICATING, SAVE_USER_DATA } from '../../../store/actions';
import { getDeviceDetails } from '../../../utils';
import { CustomLoadingButton } from '../../../ui-elements/CustomLoading';

// ===============================|| JWT LOGIN ||=============================== //

const FireabseSignUp = ({ loginProp, ...others }) => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const { auth_loading } = useSelector((state) => state.account)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { firebaseEmailPasswordSignIn,
        createUser, firebaseGoogleSignIn,
        saveUserDataGoogleLogin }
        = useAuth();

    const [showPassword, setShowPassword] = React.useState(false);
    const [googleLoginLoading, setGoogleLoginLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");


    //state to manage password strength
    const [passwordStrength, setPasswordStrength] = React.useState({
        minLength: false,
        hasNumber: false,
        hasSpecialChar: false,
    });

    const validatePassword = (value) => {
        if (!value) return false;
        const minLength = value && value.length >= 8;
        const hasNumber = /\d/.test(value);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

        setPasswordStrength({
            minLength,
            hasNumber,
            hasSpecialChar,
        });
        return minLength && hasNumber && hasSpecialChar;
    };

    const showMessage = (type, message) => {
        dispatch(openSnackbar({
            open: true,
            message: message,
            variant: 'alert',
            alert: { color: type },
        }));
    }

    const getUserDeviceDetails = async () => {
        const data = await getDeviceDetails();
        return {
            device_name: data.device_name,
            ip_address: data.ip_address,
            platform: data.platform
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const googleLogin = async () => {
        try {
            setGoogleLoginLoading(true)
            //logging in with google 
            await firebaseGoogleSignIn()
                .then(async (response) => {
                    //After login with google is successful then saving into the mongoDB
                    const userDetails = response?.user;
                    const uid = userDetails?.uid;
                    const device_info = await getUserDeviceDetails()

                    const data = {
                        first_name: userDetails?.displayName,
                        email: userDetails?.email,
                        phone_number: userDetails?.phoneNumber,
                        uid: userDetails?.uid,
                        firebase_token: userDetails?.accessToken,
                        device_info: device_info,
                    }

                    try {
                        await saveUserDataGoogleLogin(data)
                    }
                    catch (error) {
                        //if two factor enabled
                        const newData = {
                            ...data,
                            auth_type: 'google',
                        }
                        dispatch({
                            type: SAVE_USER_DATA,
                            payload: {
                                user: newData,
                            }
                        });
                        if (error?.errorCode === 'TWO_FACTOR_REQUIRED') {
                            navigate('/auth/two-factor')
                            return;
                        }
                        setErrorMessage(error?.message || "Error occurred while logging in")
                    }
                    finally {
                        setGoogleLoginLoading(false)
                    }

                })
                .catch((error) => {
                    const message = getFirebaseErrorMessage(error)
                    if (message === 'Login popup closed') {
                        return;
                    }
                    showMessage("error", message || error?.error || "Error occurred while logging in!")
                })
        }
        catch (err) {
            setErrorMessage(err?.message || err?.error || "Something went wrong.")
        }
        finally {
            setGoogleLoginLoading(false)
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    first_name: Yup.string().min(3).max(24).required('First name is required'),
                    last_name: Yup.string().min(3).max(24).notRequired(),
                    email: Yup.string().max(255).required('Email is required'),
                    password: Yup.string()
                        .max(255, "Maximum 255 characters")
                        .required("Password is required")
                        .test(
                            "is-strong-password",
                            "Password must be at least 8 characters, include a number, and a special character",
                            (value) => validatePassword(value)
                        ),
                })}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={async (values, { setSubmitting }) => {
                    try {

                        //setting loading status.
                        dispatch({
                            type: IS_AUTHENTICATING,
                            payload: {
                                auth_loading: true,
                            }
                        });

                        const device_info = await getUserDeviceDetails()
                        const newUserDetails = {
                            first_name: values.first_name,
                            last_name: values.last_name,
                            email: values.email,
                            password: values?.password,
                            device_info
                        }

                        await createUser(newUserDetails)
                            .then(async (res) => {
                                await firebaseEmailPasswordSignIn(values.email, values.password)
                            })
                            .catch((error) => {
                                //throwing the error so that catch can catch it😂
                                throw new Error(error?.message)
                            })
                    }
                    catch (err) {
                        setErrorMessage(err?.message || "Something went wrong.")

                        //ending the loading effect if an error occurred while saving user data.
                        dispatch({
                            type: IS_AUTHENTICATING,
                            payload: {
                                auth_loading: false,
                            }
                        });
                    }
                }}
            >
                {({ setErrors, errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <div
                            style={{
                                color: theme.palette.text.dark,
                            }}
                            className='flex flex-col items-center lg:items-start w-full'
                        >
                            <h2 className='text-xl sm:text-2xl font-semibold text-primary'>Create Account</h2>
                            <h2 className='text-base'></h2>
                        </div>

                        <Box sx={{ mt: '30px', mb: '20px' }}>
                            <CustomLoadingButton
                                isLoading={googleLoginLoading}
                                handleClick={googleLogin}
                                text={
                                    <div className='flex items-center gap-1 justify-center'>
                                        <img src={googleIcon} alt='icon' className='h-5 w-5 mr-2' />
                                        <span>Google</span>
                                    </div>
                                }
                                sx={{
                                    padding: '10px 20px',
                                    width: '100%'
                                }}
                                textStyle="py-1"
                            />
                        </Box>
                        <div className='w-full flex items-center justify-center'>
                            <Divider
                                textAlign="center"
                                className='w-full'
                                sx={{
                                    color: theme.palette.text.secondary,
                                    my: '10px'
                                }}
                            >
                                Or
                            </Divider>
                        </div>

                        {errorMessage ?
                            <div className={`${Styles.error_box} p-3`}>
                                {errorMessage}
                            </div>
                            : <></>
                        }
                        <div className={Styles.form}>
                            <div className='flex flex-col gap-6'>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth error={Boolean(errors.first_name)}>
                                            <OutlinedInput
                                                placeholder='First Name'
                                                type="first_name"
                                                value={values.first_name}
                                                name="first_name"
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                    handleChange(event)
                                                    setErrors({ ...errors, first_name: null })
                                                }}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Icon icon="prime:user-edit" className='text-[23px]' />
                                                    </InputAdornment>
                                                }
                                                inputProps={{ className: Styles.input }}
                                            />
                                            {errors.first_name && (
                                                <FormHelperText className={Styles.error_message} id="standard-weight-helper-text-email-login">
                                                    {errors.first_name}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth >
                                            <OutlinedInput
                                                placeholder='Last Name'
                                                type="last_name"
                                                value={values.last_name}
                                                name="last_name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Icon icon="prime:user-edit" className='text-[23px]' />
                                                    </InputAdornment>
                                                }
                                                inputProps={{ className: Styles.input }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <FormControl fullWidth error={Boolean(errors.email)}>
                                    <OutlinedInput
                                        placeholder='Email Address'
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            handleChange(event)
                                            setErrors({ ...errors, email: null })
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Icon icon="carbon:email" className='text-[20px]' />
                                            </InputAdornment>
                                        }
                                        inputProps={{ className: Styles.input }}
                                    />
                                    {errors.email && (
                                        <FormHelperText className={Styles.error_message} id="standard-weight-helper-text-email-login">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth error={Boolean(errors.password)}>
                                    <OutlinedInput
                                        placeholder='Password'
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        autoComplete="new-password"
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            validatePassword(value)
                                            setFieldValue('password', value, true);

                                            setErrors({ ...errors, password: null })
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Icon icon="clarity:lock-line" className='text-[23px]' />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showPassword ?
                                                        <Icon icon="clarity:eye-show-line" className='text-[25px]' /> :
                                                        <Icon icon="clarity:eye-hide-line" className='text-[25px]' />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        inputProps={{ className: Styles.input }}
                                    />
                                    {errors.password && (
                                        <FormHelperText className={Styles.error_message} id="standard-weight-helper-text-password-login">
                                            {errors.password}
                                        </FormHelperText>
                                    )}

                                    <div className='flex flex-col gap-1 mt-2'>
                                        <div className='flex items-center gap-2 text-[12px]'>
                                            <CheckCircleOutline
                                                style={{
                                                    color: passwordStrength?.minLength ? '#00A352' : theme.palette.text.secondary
                                                }}
                                            />
                                            <p
                                                style={{
                                                    color: passwordStrength?.minLength ? '#00A352' : theme.palette.text.secondary
                                                }}
                                            >
                                                Must be at least 8 characters
                                            </p>
                                        </div>
                                        <div className='flex items-center gap-2 text-[12px]'>
                                            <CheckCircleOutline
                                                style={{
                                                    color: passwordStrength?.hasSpecialChar ? '#00A352' : theme.palette.text.secondary
                                                }}
                                            />
                                            <p
                                                style={{
                                                    color: passwordStrength?.hasSpecialChar ? '#00A352' : theme.palette.text.secondary
                                                }}
                                            >
                                                Must include a special character
                                            </p>
                                        </div>
                                        <div className='flex items-center gap-2 text-[12px]'>
                                            <CheckCircleOutline
                                                style={{
                                                    color: passwordStrength?.hasNumber ? '#00A352' : theme.palette.text.secondary
                                                }}
                                            />
                                            <p
                                                style={{
                                                    color: passwordStrength?.hasNumber ? '#00A352' : theme.palette.text.secondary
                                                }}
                                            >
                                                Must include a number
                                            </p>
                                        </div>
                                    </div>
                                </FormControl>
                            </div>

                            <Box sx={{ mt: 3 }}>
                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={auth_loading}
                                    style={{
                                        padding: '12px 0px',
                                        borderRadius: '10px'
                                    }}
                                // disabled={!passwordStrength.minLength || !passwordStrength.hasNumber || !passwordStrength.hasSpecialChar}
                                >
                                    Sign Up
                                </LoadingButton>
                            </Box>

                        </div>
                    </form>
                )}
            </Formik>

        </>
    );
};

FireabseSignUp.propTypes = {
    loginProp: PropTypes.number
};

export default FireabseSignUp;
