/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    outboundCalls: null,
    singleOutboundCall: null,
    loading: false,
    obcStartLoading: false,

    totalPages: 0,
    totalCount: 0,
};

const slice = createSlice({
    name: 'outboundCall',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isObcStartLoading(state, action) {
            state.obcStartLoading = action.payload;
        },

        setOutboundCallDataSuccess(state, action) {
            state.outboundCalls = action.payload?.data;
            state.totalPages = action.payload?.totalPages;
            state.totalCount = action.payload?.totalCount;
        },
        setSingleOutboundCallDataSuccess(state, action) {
            state.singleOutboundCall = action.payload;
        },
        deleteOutboundCallDataSuccess(state, action) {
            const newData = state.outboundCalls.filter(outboundCall => outboundCall._id !== action.payload.id);
            state.outboundCalls = newData;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------



export function getAllOutboundCalls({ page, limit, search }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/outbound_call/all?search=${search}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setOutboundCallDataSuccess(response.data));
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}

export function getSingleOutboundCall(obc_id) {
    return async () => {
        try {

            const response = await axios.get(`/outbound_call/get/${obc_id}`);
            dispatch(slice.actions.setSingleOutboundCallDataSuccess(response.data.data));

        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            // dispatch(slice.actions.isLoading(false));
        }
    }
}

export function createOutboundCall(data) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.post(`/outbound_call/create`, data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            handleSuccess(response, "Outbound call created successfully", dispatch);

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}

export function updateOutboundCall(obc_id, data) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.put(`/outbound_call/update/${obc_id}`, data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            handleSuccess(response, "Outbound call updated successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}

export function deleteOutboundCall(obc_id) {
    return async () => {
        try {
            const response = await axios.delete(`/outbound_call/delete?obc_id=${obc_id}`);
            dispatch(slice.actions.deleteOutboundCallDataSuccess(
                { id: obc_id }
            ))
            handleSuccess(response, "Outbound call deleted successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
    }
}

export function startOutboundCall({ obc_id, follow_up_id, user_id, org_id, settings }) {
    return async () => {
        try {
            const data = { obc_id, follow_up_id, user_id, org_id, settings}

            dispatch(slice.actions.isObcStartLoading(true));
            const response = await axios.post(`/outbound_call/start-calling`, data);
            // handleSuccess(response, "Outbound call started successfully", dispatch);
            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isObcStartLoading(false));
        }
    }
}

export function downloadObcLogFile({ follow_up_id }) {
    return async () => {
        try {
            const timezone_code = Intl.DateTimeFormat().resolvedOptions().timeZone;

            console.log(`Downloading ${timezone_code}`)

            const response = await axios.post(`/outbound_call/download-log`, { follow_up_id, timezone_code });

            //----------start of downloading the logfile--------------
            const data = response.data;
            const link = document.createElement('a');
            link.href = `data:${data.fileType};base64,${data.base64File}`;
            link.download = data.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            //----------end of downloading the logfile--------------

            return data
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
    }
}