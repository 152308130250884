
/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';
import { dispatch } from '../../index';
import { handleError, handleSuccess } from '../../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    agents: null,
    singleAgent: null,
    loading: false,
    saveLoading: false,
    totalPages: null,
    totalCount: null,
};

const slice = createSlice({
    name: 'adminAgent',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isSaveLoading(state, action) {
            state.saveLoading = action.payload;
        },

        setAgentDataSuccess(state, action) {
            state.agents = action.payload?.data;
            state.totalCount = action.payload?.totalCount;
            state.totalPages = action.payload?.totalPages;
        },

        setSingleAgentDataSuccess(state, action) {
            state.singleAgent = action.payload;
        },

        updateAgentDataSuccess(state, action) {
            const index = state.agents.findIndex(agent => agent._id === action.payload.id);
            const agentData = state.agents[index];
            const newData = { ...agentData, ...action.payload.data };
            state.agents[index] = newData
        },

        createAgentDataSuccess(state, action) {
            state.agents.push(action.payload);
        },

        deleteAgentDataSuccess(state, action) {
            const newAgents = state.agents.filter(agent => agent._id !== action.payload.id);
            state.agents = newAgents;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllAgents({ page, limit, search }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/agent?search=${search}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setAgentDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}
export function getSingleAgent({ id }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/agent/${id}`);
            dispatch(slice.actions.setSingleAgentDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}

export function createAgent(data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.post(`/admin/agent`, data);
            dispatch(slice.actions.createAgentDataSuccess(response.data))

            handleSuccess(response, "Agent created successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function updateAgent(id, data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.put(`/admin/agent/${id}`, data);

            dispatch(slice.actions.updateAgentDataSuccess(
                { data, id }
            ))
            handleSuccess(response, "Agent updated successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function deleteAgent(id) {
    return async () => {
        try {
            const response = await axios.delete(`/admin/agent/${id}`);
            dispatch(slice.actions.deleteAgentDataSuccess(
                { id }
            ))
            handleSuccess(response, "Agent deleted successfully", dispatch);

            return response.data?.message;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
    }
}
