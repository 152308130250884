
/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';
import { dispatch } from '../../index';
import { handleError, handleSuccess } from '../../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    users: null,
    singleUser: null,
    loading: false,
    saveLoading: false,
    totalPages: null,
    totalCount: null,
};

const slice = createSlice({
    name: 'adminUser',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isSaveLoading(state, action) {
            state.saveLoading = action.payload;
        },

        setUserDataSuccess(state, action) {
            state.users = action.payload?.data;
            state.totalCount = action.payload?.totalCount;
            state.totalPages = action.payload?.totalPages;
        },

        setSingleUserDataSuccess(state, action) {
            state.singleUser = action.payload;
        },

        updateUserDataSuccess(state, action) {
            const index = state.users.findIndex(user => user._id === action.payload.id);
            const userData = state.users[index];
            const newData = { ...userData, ...action.payload.data };
            state.users[index] = newData
        },

        createUserDataSuccess(state, action) {
            state.users.push(action.payload);
        },

        deleteUserDataSuccess(state, action) {
            const newUsers = state.users.filter(user => user._id !== action.payload.id);
            state.users = newUsers;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllUsers({ page, limit, search }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/user?search=${search}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setUserDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}
export function getSingleUser({ id }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/user/${id}`);
            dispatch(slice.actions.setSingleUserDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}

export function createUser(data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.post(`/admin/user`, data);
            dispatch(slice.actions.createUserDataSuccess(response.data))

            handleSuccess(response, "User created successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function updateUser(id, data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.put(`/admin/user/${id}`, data);

            dispatch(slice.actions.updateUserDataSuccess(
                { data, id }
            ))
            handleSuccess(response, "User updated successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function deleteUser(id) {
    return async () => {
        try {
            const response = await axios.delete(`/admin/user/${id}`);
            dispatch(slice.actions.deleteUserDataSuccess(
                { id }
            ))
            handleSuccess(response, "User deleted successfully", dispatch);

            return response.data?.message;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
    }
}
