/* eslint-disable */
import React from 'react'
import Styles from "../../../styles/dialog.module.scss"
import Logo from '../../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import AuthForgetPassword from './AuthForgetPassword';

function ForgetPassword(props) {

    const navigate = useNavigate()

    return (
        <div className='mt-[20px] lg:mt-[50px]'>
            <AuthForgetPassword />

            <div className='flex justify-center lg:justify-start text-black gap-1 mt-8'>
                <p className='opacity-60'>I have an account?{' '}
                </p>
                <span
                    className='text-primary cursor-pointer'
                    role='button'
                    onClick={() => navigate('/login')}
                >
                    Login
                </span>
            </div>
        </div>
    )
}

export default ForgetPassword
