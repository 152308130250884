import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import Error from '../views/pages/error-page';
import AdminRoutes from './AdminRoutes';

export default function ThemeRoutes() {

    return useRoutes([
        {
            path: '/', element:
                <Navigate to="/login" replace />
        },
        { path: '*', element:  <Navigate to="/dashboard/numbers" replace />  },  //<Error />
        LoginRoutes, MainRoutes, AdminRoutes
    ]);
}
