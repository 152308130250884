/* eslint-disable */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
// material-ui
import { Grid, TextField, Button, IconButton, FormHelperText, FormControl } from '@mui/material';

import { gridSpacing } from '../../store/constant';
import { useDispatch, useSelector } from '../../store';
import { Formik } from 'formik';
import * as Yup from 'yup';

// assets
import MainCard from '../../ui-elements/MainCard';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react/dist/iconify.js';
import { getAllPhoneNumbersOfUser } from '../../store/slices/phoneNumbers';
import { MuiSelect } from '../../ui-elements/Select';

import axios from 'axios';
import { openSnackbar } from '../../store/slices/snackbar';
import { StorageManager } from '../../utils/storageManager';
import { getAllAgents } from '../../store/slices/agent';
import { MuiTelInput } from 'mui-tel-input';
import useConfig from '../../hooks/useConfig';

const HDFCDemoObcCall = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const theme = useTheme()
    const mode = theme.palette.mode;
    const { matchDownMd } = useConfig()

    const [data, setData] = React.useState(null);
    const [startCallLoading, setStartCallLoading] = React.useState(false)
    const [error, setError] = React.useState({ agent_phone_number: '' })

    const { phoneNumbers } = useSelector((state) => state.phoneNumbers)
    const { agents } = useSelector((state) => state.agent)

    React.useEffect(() => {
        async function fetch() {
            try {
                await dispatch(getAllAgents())
            }
            catch (error) {
                console.log(error)
            }
        }
        fetch()
    }, [])

    React.useEffect(() => {
        async function fetch() {
            try {
                await dispatch(getAllPhoneNumbersOfUser({ page: 1, limit: 100, phone_number: '' }))
            }
            catch (error) {
                console.log(error)
            }
        }
        fetch()
    }, [])

    const handleSetCustomError = (name, message) => {
        setError(prevState => ({ ...prevState, [name]: message }));
    }

    const validationSchema = Yup.object().shape({
        user_phone_number: Yup.string()
            .required('User phone number is required')
            .matches(/^\+91\d{10}$/, 'Please enter a valid phone number'),
        agent_phone_number: Yup.string().required('Agent Phone Number is required'),
        prefix_code: Yup.string().notRequired(),
    })

    const showMessage = (type, message) => {
        dispatch(openSnackbar({
            open: true,
            message: message,
            variant: 'alert',
            alert: { color: type },
            close: true
        }))
    }

    return (
        <>
            <MainCard
                title="Demo Outbound Call"
                content={true}
            >
                <Grid
                    style={{
                        backgroundColor: !matchDownMd && theme.palette.background.default,
                        height: '85vh',
                        padding: !matchDownMd && '30px',
                        borderRadius: !matchDownMd && '8px'
                    }}
                >
                    <>
                        <Formik
                            initialValues={{
                                user_phone_number: data?.user_phone_number || '+91',
                                phone_number_id: data?.phone_number_id || '',
                                prefix_code: data?.prefix_code || '',
                                agent_phone_number: data?.agent_phone_number || '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={async (values, { setErrors }) => {

                                try {
                                    //get the phone_number from the phoneNumbers using phone_number_id
                                    const active_org = new StorageManager('ACTIVE_ORG', 'OTHERS').getStorage();

                                    const org_id = active_org?.org_id;
                                    const user_phone_number = values?.user_phone_number?.replace(/\s+/g, ' ');

                                    const newValues = {
                                        caller_number: values.agent_phone_number,
                                        org_id: org_id,
                                        prefix_code: values?.prefix_code,
                                        user_phone_number: user_phone_number
                                    }

                                    const obc_server_url = process.env.REACT_APP_OUTBOUND_CALL_SERVER_URL;

                                    setStartCallLoading(true)

                                    await axios.post(`${obc_server_url}/demo_outbound/`, newValues, {
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    })
                                        .then((res) => {
                                            console.log(res.data);
                                            showMessage("success", "Outbound call sent successfully");
                                            setStartCallLoading(false)

                                        })
                                        .catch((error) => {
                                            console.error(error);
                                            showMessage("error", "Something went wrong.");
                                            setStartCallLoading(false)

                                        });
                                }
                                catch (error) {

                                }
                            }}
                        >
                            {({ errors, handleSubmit, handleChange, handleBlur, values, touched, setFieldValue }) => (
                                <Grid item xs={12} className='flex flex-col justify-between'>
                                    <Grid container spacing={gridSpacing}>

                                        <Grid item xs={12} sm={12}>
                                            <h1 className="text-md mb-2 font-medium" >
                                                Select agent
                                                <span className='text-red-400'>*</span>
                                            </h1>
                                            <Grid container xs={12} sm={6}>
                                                <FormControl sx={{ minWidth: '100%' }}>
                                                    <MuiSelect
                                                        name='agent'
                                                        placeholder={"Select an agent"}
                                                        value={values.agent}
                                                        handleChange={async (event) => {
                                                            handleChange(event)
                                                            phoneNumbers?.map((phoneNumber) => {
                                                                if (phoneNumber?._id === event.target?.value) {
                                                                    setFieldValue('prefix_code', phoneNumber?.phone_number_id?.prefix_code)
                                                                    setFieldValue('agent_phone_number', phoneNumber?.phone_number)
                                                                }
                                                            })
                                                        }}
                                                        items={
                                                            phoneNumbers?.map((pn_number) => {
                                                                //check whether any agent linked to this number or not.
                                                                const assigned_agent = pn_number?.assigned_to_id;
                                                                const agent_name = pn_number?.assigned_to_id?.name || 'No agent linked';
                                                                const disabled = !assigned_agent;
                                                                const number = pn_number?.phone_number;
                                                                const taken_number_id = pn_number?._id

                                                                const extraLabel = disabled ?
                                                                    <Icon icon="ph:warning-circle-light" className='w-4 h-4' />
                                                                    : ""

                                                                return {
                                                                    value: `${taken_number_id}`,
                                                                    label:
                                                                        <div className='flex flex-wrap items-center justify-between gap-1 w-full'>
                                                                            <div className='flex flex-wrap gap-2 items-center'>
                                                                                <p>{agent_name} </p>
                                                                                <p>{extraLabel}</p>
                                                                            </div>
                                                                            <p className='opacity-1 text-xs text-white bg-primary rounded-full px-2 py-1'>{number}</p>
                                                                        </div>,
                                                                    renderValue: <>{agent_name}</>,
                                                                    disabled: disabled
                                                                }
                                                            })
                                                        }
                                                    />
                                                </FormControl>
                                                {touched.agent && errors.agent && (
                                                    <FormHelperText error id="standard-weight-helper-text-first_name-login">
                                                        {errors.agent}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <h1 className="text-md mb-2 font-medium" >
                                                User number
                                                <span className='text-red-400'>*</span>
                                            </h1>

                                            <Grid container xs={12} sm={6}>
                                                <MuiTelInput
                                                    name="user_phone_number"
                                                    fullWidth
                                                    placeholder="7987653490"
                                                    value={values.user_phone_number}
                                                    onChange={(newVal) => {
                                                        const trimmedNum = newVal.replace(/\s+/g, '')
                                                        setFieldValue('user_phone_number', trimmedNum)
                                                    }}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    defaultCountry="IN"
                                                    onlyCountries={['IN']}
                                                />
                                                {touched.user_phone_number && errors.user_phone_number && (
                                                    <FormHelperText error id="standard-weight-helper-text-first_name-login">
                                                        {errors.user_phone_number}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container justifyContent="start" marginTop={4} className='gap-3'>
                                        <Grid item>
                                            <LoadingButton
                                                variant="contained"
                                                size="large"
                                                onClick={handleSubmit}
                                                loading={startCallLoading}
                                            >
                                                Send call
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Formik>
                    </>

                </Grid>
            </MainCard >
        </>
    )
};

export default HDFCDemoObcCall;
