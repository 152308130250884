// // import React from 'react';

// // // material-ui
// // import { useTheme } from '@mui/material/styles';
// // import { useMediaQuery, ClickAwayListener } from '@mui/material';
// // import { useSelector, useDispatch } from '../../../store';
// // import { closeDetailsDrawer } from '../../../store/slices/detailsDrawer';
// // import Transitions from '../../../ui-elements/Transitions';

// // // ===============================|| UI DIALOG - RESPONSIVE ||=============================== //

// // export default function DetailsDrawer() {
// //     const theme = useTheme();
// //     const mode = theme.palette.mode;

// //     const dispatch = useDispatch();
// //     const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

// //     const { open, title, element, style } = useSelector((state) => state.detailsDrawer);

// //     const handleClose = (event) => {
// //         const buttonId = event?.target?.id;
// //         if (buttonId && buttonId === 'open_dd_button') {
// //             return;
// //         }
// //         dispatch(closeDetailsDrawer());
// //     }

// //     return (
// //         <ClickAwayListener onClickAway={handleClose}>
// //             <Transitions
// //                 type="slide"
// //                 direction="right"
// //                 in={open}
// //                 timeout={500}
// //             >
// //                 {open &&

// //                     <div className={`fixed right-0 ${!matchDownMd ? 'top-[0rem]' : 'top-[3.3rem]'} pb-[5.5rem] h-screen w-full sm:w-[30rem] shadow-lg z-50 overflow-y-auto`}
// //                         style={{
// //                             background: mode === 'dark' ? theme.palette.background.gradientDark : theme.palette.background.default,
// //                             borderRadius: '10px',
// //                             ...style
// //                         }}
// //                     >
// //                         <div
// //                             className='flex items-center justify-between px-6 py-4'
// //                             style={{
// //                                 color: theme.palette.text.primary
// //                             }}
// //                         >
// //                             <h2 className='text-lg font-semibold'>{title}</h2>
// //                             <button className="mb-1 text-xl" onClick={handleClose}>
// //                                 &times;
// //                             </button>
// //                         </div>
// //                         <hr className='mb-2' />

// //                         <div className='px-6'>
// //                             {element}
// //                         </div>
// //                     </div>
// //                 }
// //             </Transitions>
// //         </ClickAwayListener>
// //     );
// // }


// import React from 'react';

// // material-ui
// import { useTheme } from '@mui/material/styles';
// import { useMediaQuery, ClickAwayListener } from '@mui/material';
// import { useSelector, useDispatch } from '../../../store';
// import { closeDetailsDrawer } from '../../../store/slices/detailsDrawer';
// import Transitions from '../../../ui-elements/Transitions';

// // ===============================|| UI DIALOG - RESPONSIVE ||=============================== //

// export default function DetailsDrawer() {
//     const theme = useTheme();
//     const mode = theme.palette.mode;

//     const dispatch = useDispatch();
//     const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

//     const { open, title, element, style } = useSelector((state) => state.detailsDrawer);

//     const handleClose = (event) => {
//         const buttonId = event?.target?.id;
//         if (buttonId && buttonId === 'open_dd_button') {
//             return;
//         }
//         dispatch(closeDetailsDrawer());
//     };

//     console.log("open", open)

//     return (
//         <div>
//             {open &&
//                 <ClickAwayListener onClickAway={handleClose}>
//                     <div
//                         className={`fixed right-0 ${!matchDownMd ? 'top-[0rem]' : 'top-[3.3rem]'} pb-[5.5rem] h-screen w-full sm:w-[30rem] shadow-lg z-50 overflow-y-auto transition-all duration-75`}
//                         style={{
//                             background: mode === 'dark' ? theme.palette.background.gradientDark : theme.palette.background.default,
//                             borderRadius: '10px',
//                             ...style,
//                         }}
//                     >
//                         <div
//                             className='flex items-center justify-between px-6 py-4'
//                             style={{
//                                 color: theme.palette.text.primary,
//                             }}
//                         >
//                             <h2 className='text-lg font-semibold'>{title}</h2>
//                             <button className="mb-1 text-xl" onClick={handleClose}>
//                                 &times;
//                             </button>
//                         </div>
//                         <hr className='mb-2' />
//                         <div className='px-6'>{element}</div>
//                     </div>
//                 </ClickAwayListener>
//             }
//         </div>
//     );
// }


// import React from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useMediaQuery, ClickAwayListener } from '@mui/material';
// import { useSelector, useDispatch } from '../../../store';
// import { closeDetailsDrawer } from '../../../store/slices/detailsDrawer';
// import { motion } from 'framer-motion';

// // ===============================|| UI DIALOG - RESPONSIVE ||=============================== //

// export default function DetailsDrawer() {
//     const theme = useTheme();
//     const mode = theme.palette.mode;

//     const dispatch = useDispatch();
//     const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

//     const { open, title, element, style } = useSelector((state) => state.detailsDrawer);

//     const handleClose = (event) => {
//         const buttonId = event?.target?.id;
//         if (buttonId && buttonId === 'open_dd_button') {
//             return;
//         }
//         dispatch(closeDetailsDrawer());
//     };

//     const drawerVariants = {
//         hidden: {
//             x: '100%', // Off-screen to the right
//             opacity: 0,
//         },
//         visible: {
//             x: 0, // On-screen
//             opacity: 1,
//             transition: { type: 'spring', stiffness: 300, damping: 30, duration: 0.5 },
//         },
//         exit: {
//             x: '100%', // Slide back to the right
//             opacity: 0,
//             transition: { type: 'spring', stiffness: 300, damping: 30, duration: 0.5 },
//         },
//     };

//     return (
//         // <ClickAwayListener onClickAway={handleClose}>
//             <div>
//                 {open && (
//                     <motion.div
//                         initial="hidden"
//                         animate="visible"
//                         exit="exit"
//                         variants={drawerVariants}
//                         className={`fixed right-0 ${!matchDownMd ? 'top-[0rem]' : 'top-[3.3rem]'} pb-[5.5rem] h-screen w-full sm:w-[30rem] shadow-lg z-50 overflow-y-auto`}
//                         style={{
//                             background: mode === 'dark' ? theme.palette.background.gradientDark : theme.palette.background.default,
//                             borderRadius: '10px',
//                             ...style,
//                         }}
//                     >
//                         <div
//                             className="flex items-center justify-between px-6 py-4"
//                             style={{
//                                 color: theme.palette.text.primary,
//                             }}
//                         >
//                             <h2 className="text-lg font-semibold">{title}</h2>
//                             <button className="mb-1 text-xl" onClick={handleClose}>
//                                 &times;
//                             </button>
//                         </div>
//                         <hr className="mb-2" />
//                         <div className="px-6">{element}</div>
//                     </motion.div>
//                 )}
//             </div>
//         // </ClickAwayListener>
//     );
// }


import React from 'react';
import { Drawer, Box, Typography, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from '../../../store';
import { closeDetailsDrawer } from '../../../store/slices/detailsDrawer';
import CloseIcon from '@mui/icons-material/Close';
import { ClickableCancelIcon } from '../../../ui-elements/IconifyIcon';

// ===============================|| UI DRAWER - RESPONSIVE ||=============================== //

export default function DetailsDrawer() {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const dispatch = useDispatch();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const { open, title, element, style } = useSelector((state) => state.detailsDrawer);

    const handleClose = () => {
        dispatch(closeDetailsDrawer());
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: matchDownMd ? '100%' : '42rem',
                    backgroundColor: mode === 'dark' ? theme.palette.background.gradientDark : theme.palette.background.default,
                    borderRadius: '10px 0 0 10px', // Rounded edges for the drawer
                    overflowY: 'auto',
                    ...style
                },
            }}
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Set backdrop color and opacity
                },
            }}
        >
            <Box sx={{ px: 3, py: 2 }}>
                <div
                    className="flex items-center justify-between px-1 py-2"
                    style={{
                        color: theme.palette.text.primary,
                    }}
                >
                    <h2 className="text-lg font-semibold">{title}</h2>
                    <ClickableCancelIcon
                        handleClick={handleClose}
                        iconStyle={{
                            height: '25px',
                            width: '25px',
                        }}
                    />
                </div>
                <hr className="mb-2" style={{ borderColor: theme.palette.divider }} />
                <Box sx={{ }}>
                    {element}
                </Box>
            </Box>
        </Drawer>
    );
}
