
import React, { useState } from 'react';
import { Button, Menu, MenuItem, Avatar, Divider, ListItemIcon, CircularProgress, ListSubheader } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconUser, IconLogout, IconBuilding } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../../../store';
import useAuth from '../../../../hooks/useAuth';
import { Icon } from '@iconify/react/dist/iconify.js';
import { LoadingButton } from '@mui/lab';
import ModeSwitch from './ModeSwitch';

const ProfileMenu = ({ drawerOpen, level }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    const [anchorEl, setAnchorEl] = useState(null);
    const [loggingOut, setLoggingOut] = useState(false);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const { data: userData } = useSelector(state => state.account)
    const { logout } = useAuth();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfileClick = () => {
        navigate('/dashboard/user-profile');
        handleClose();
    };

    const handleOrganizationClick = () => {
        navigate('/dashboard/organization');
        handleClose();
    };

    const handleLogoutClick = async () => {
        setLoggingOut(true);

        await new Promise(resolve => setTimeout(resolve, 2000));

        setLoggingOut(false)

        logout()
    };

    return (
        <div className={``}>
            <Button
                aria-controls={open ? 'profile-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={`flex items-center justify-start gap-2 mb-4`}
                sx={{ width: '95%' }}
            >
                <Avatar className='bg-[#D9F5FF]'>
                    {userData?.first_name?.charAt(0).toUpperCase()}
                </Avatar>
                {(drawerOpen || (!drawerOpen && level !== 1)) && (
                    <span className='text-[16px]'>{userData?.first_name}</span>
                )}
            </Button>

            <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        width: '220px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                style={{
                    wordWrap: 'break-word'
                }}
            >
                <ListSubheader style={{ lineHeight: '22px', padding: '16px' }}>
                    <div className='flex flex-col gap-1 items-start'>
                        <div className='flex flex-col items-start'>
                            <p
                                className='font-semibold opacity-80 text-base gap-1'
                                style={{
                                    color: theme.palette.text.primary
                                }}
                            >
                                {userData?.first_name}
                            </p>
                            <p
                                className='text-xs'
                                style={{
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                    color: theme.palette.text.secondary,
                                }}
                            >
                                {userData?.email}
                            </p>
                            <div className='flex  gap-2 items-baseline justify-between w-full'>
                                {userData?.subscription ?
                                    <div className='px-[10px] py-[3px] bg-primary flex items-center gap-[6px] rounded-md text-white mt-3'>
                                        <Icon icon="fluent:premium-16-regular" className='' />
                                        <p className='text-xs'>Premium</p>
                                    </div>
                                    :
                                    <div className='mt-3'>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            className="px-4 py-[6px] text-xs"
                                            onClick={() => navigate('/dashboard/organization?tab=subscription-details')}
                                        >
                                            Subscription
                                        </LoadingButton>
                                    </div>

                                }
                                <ModeSwitch />
                            </div>
                        </div>
                    </div>

                </ListSubheader>

                <Divider
                    className='my-1 '
                    sx={{
                        backgroundColor: theme.palette.border.main,
                    }}
                />

                <MenuItem onClick={handleProfileClick}>
                    <ListItemIcon>
                        <IconUser className='h-[22px] w-[22px]' />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                <MenuItem onClick={handleOrganizationClick}>
                    <ListItemIcon>
                        <IconBuilding className='h-[22px] w-[22px]' />
                    </ListItemIcon>
                    Organization
                </MenuItem>
                <MenuItem onClick={handleLogoutClick}>
                    <ListItemIcon>
                        <IconLogout className='h-[22px] w-[22px]' />
                    </ListItemIcon>
                    {!loggingOut ?
                        "Logout" :
                        <CircularProgress size={18} />
                    }
                </MenuItem>
            </Menu>
        </div>
    );
};

export default ProfileMenu;
