import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Material-UI
import { useTheme } from '@mui/material/styles';
import { Avatar, ButtonBase, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// Project imports
import LAYOUT_CONST from '../../../../constant';
import useConfig from '../../../../hooks/useConfig';
import { useDispatch, useSelector } from '../../../../store';
import { activeID, activeItem, openDrawer } from '../../../../store/slices/menu';

// Assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { icons } from '../../../../menu-items';

const NavItem = ({ item, level, parentId }) => {
    const theme = useTheme();
    const palette = theme.palette;
    const mode = palette.mode;

    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { layout, borderRadius } = useConfig();

    const { selectedItem, drawerOpen } = useSelector((state) => state.menu);
    const { loadingMenuItems } = useSelector((state) => state.dashboard)

    // Check if the item URL matches the current path or any of its nested paths
    const isPathMatched = pathname.startsWith(item.url);
    const [isSelected, setIsSelected] = useState(isPathMatched);

    useEffect(() => {
        setIsSelected(isPathMatched);
    }, [pathname, isPathMatched]);

    const Icon = item?.icon;
    const NewIcon = icons[Icon]
    const itemIcon = item?.icon ? (
        <NewIcon
            stroke={2}
            size={drawerOpen ? '20px' : '25px'}
            style={{
                color: mode === 'light' ? (isSelected ? palette.primary.main : palette.text.primary)
                    : (isSelected ? palette.text.light : palette.text.main)
            }}
        />
    ) : (
        <FiberManualRecordIcon
            sx={{
                color: mode === 'light' ? (isSelected ? palette.primary.main : palette.text.primary)
                    : (isSelected ? palette.text.light : palette.text.main),
                width: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch(activeItem([id]));
        if (matchesSM) dispatch(openDrawer(false));
        dispatch(activeID(parentId));
    };

    // Highlight menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch(activeItem([item.id]));
        }
    }, [pathname]);


    return (
        <>
            {!loadingMenuItems && (layout === LAYOUT_CONST.VERTICAL_LAYOUT) || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    className={item?.class}
                    disableRipple={!drawerOpen}
                    sx={{
                        zIndex: 1201,
                        borderRadius: `${borderRadius}px`,
                        mb: 0.5,
                        pl: drawerOpen ? `${level * 20}px` : 1.25,
                        ...(drawerOpen &&
                            level === 1 &&
                            theme.palette.mode !== 'dark' && {
                            '&:hover': {
                                background: theme.palette.secondary.main
                            },
                            '&.Mui-selected': {
                                backgroundColor: theme.palette.secondary.main,
                                color: "#fff",
                                '&:hover': {
                                    color: theme.palette.text.primary,
                                    background: theme.palette.secondary.main
                                }
                            }
                        }),
                        ...(drawerOpen &&
                            level === 1 &&
                            theme.palette.mode === 'dark' && {
                            color: theme.palette.text.main,
                            '&:hover': {
                                background: theme.palette.secondary.main,
                                color: theme.palette.text.primary
                            },
                            '&.Mui-selected': {
                                background: theme.palette.background.gradientSecondaryDark,
                                color: theme.palette.text.primary,
                                '&:hover': {
                                    color: theme.palette.text.primary,
                                    background: theme.palette.background.gradientSecondaryDark
                                }
                            }
                        }),
                        ...((!drawerOpen || level !== 1) && {
                            py: level === 1 ? 0 : 1,
                            '&:hover': {
                                bgcolor: `${!drawerOpen ? "transparent" : theme.palette.secondary.main}`
                            },
                            '&.Mui-selected': {
                                background: level !== 1 ? (mode === "dark" ? theme.palette.background.gradientSecondaryDark : theme.palette.secondary.main) : 'transparent',
                                color: theme.palette.text.primary,
                                '&:hover': {
                                    color: theme.palette.text.primary,
                                    backgroundColor: level !== 1 ? (mode === "dark" ? theme.palette.background.gradientSecondaryDark : theme.palette.secondary.main) : 'transparent',
                                }
                            }
                        })
                    }}
                    selected={isSelected}
                    onClick={() => itemHandler(item.id)}
                >
                    <ButtonBase
                        sx={{
                            borderRadius: `${borderRadius}px`,
                            ...(!drawerOpen &&
                                level === 1 && {
                                borderRadius: `50px`,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                },
                                ...(isSelected && {
                                    bgcolor: theme.palette.secondary.main,
                                    borderRadius: `50px`,
                                    '&:hover': {
                                        borderRadius: `50px`,
                                    }
                                })
                            })
                        }}
                        disableRipple={drawerOpen}
                        aria-label="pages icon"
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: level === 1 ? 36 : 18,
                                color: isSelected ? "#fff" : theme.palette.secondary.dark,
                                ...(!drawerOpen &&
                                    level === 1 && {
                                    borderRadius: `50px`,
                                    width: 46,
                                    height: 46,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:hover': {
                                        bgcolor: theme.palette.secondary.main,
                                    },
                                    ...(isSelected && {
                                        bgcolor: mode === 'dark' ? theme.palette.background.main : theme.palette.secondary.main,
                                        '&:hover': {
                                            bgcolor: mode === 'dark' ? theme.palette.background.main : theme.palette.secondary.main,
                                        }
                                    })
                                })
                            }}
                        >
                            {itemIcon}
                        </ListItemIcon>
                    </ButtonBase>

                    {(drawerOpen || (!drawerOpen && level !== 1)) && (
                        <ListItemText
                            primary={
                                <Typography
                                    variant={isSelected ? 'h5' : 'body1'}
                                    color={drawerOpen && mode === 'light' ?
                                        (isSelected ? theme.palette.primary.main : theme.palette.text.primary)
                                        :
                                        !drawerOpen && mode === 'light' ?
                                            (isSelected ? theme.palette.primary.main : theme.palette.text.primary)
                                            :
                                            (isSelected ? theme.palette.text.light : theme.palette.text.light)
                                    }
                                >
                                    {item.title}
                                </Typography>
                            }
                            secondary={
                                item.caption && (
                                    <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                        {item.caption}
                                    </Typography>
                                )
                            }
                        />
                    )}

                    {drawerOpen && item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            ) : (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    sx={{
                        borderRadius: 0,
                        mb: 0.5,
                        alignItems: 'flex-start',
                        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                        py: 1,
                        pl: 2
                    }}
                    selected={isSelected}
                    onClick={() => itemHandler(item.id)}
                >
                    <ListItemIcon
                        sx={{
                            my: 'auto',
                            minWidth: !item?.icon ? 18 : 36
                        }}
                    >
                        {itemIcon}
                    </ListItemIcon>

                    <ListItemText
                        primary={
                            <Typography variant={isSelected ? 'h5' : 'body1'} color="inherit">
                                {item.title}
                            </Typography>
                        }
                        secondary={
                            item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )
                        }
                    />

                    {item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            )
            }
        </>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number,
    parentId: PropTypes.string
};

export default NavItem;
