/* eslint-disable */
import React from 'react'
import Styles from "../styles/header.module.scss";
import authPage from '../assets/images/authPage.png'
import { Logo } from './Logo';
import HeadTrackCursor from '../views/components/auth/HeadTrackCursor';

export const handleRedirect = () => {
    window.location.href = 'https://ringus.ai'
}
function Header(props) {
    return (
        <>
            <div className={`${Styles.header} relative`}>
                <img
                    src={"https://res.cloudinary.com/dgcicwap9/image/upload/f_webp,fl_awebp,q_auto/v1728891833/Dashboard/snmrldfgxmopd5y4akie"}
                    alt='authPageImage'
                    className='w-full h-full'
                    style={{ objectFit: 'cover' }}
                />
                <div className='flex flex-col gap-4 w-full md:w-[30%] md:items-start items-center text-center 
                md:text-left absolute left-0 lg:left-[100px] top-[40px] lg:top-[80px]'>

                    <Logo />

                    <p className='text-xs sm:text-4xl text-white font-light leading-[30px] lg:mt-[80px] hidden lg:block'>
                        <span className='text-darkPrimary font-semibold'>Transform</span>{' '}
                        the Way<br className='hidden sm:block' />{' '}
                        You{' '}
                        <span className='text-darkPrimary font-semibold'>Connect!</span>{' '}
                    </p>
                    <p className='text-sm sm:text-base text-white font-light  hidden lg:block'>
                        Experience the future of AI Calling with our<br className='hidden sm:block' />{' '}
                        personalized AI agents available 24/7.
                    </p>
                </div>
            </div >
        </>
    )
}

export default Header
