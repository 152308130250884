/* eslint-disable */
import React, { useState } from 'react'
import Styles from "../../../styles/dialog.module.scss"
import LoginStyles from "../../../styles/login.module.scss";
import Logo from '../../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { FormControl, FormHelperText, InputAdornment, OutlinedInput, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import useAuth from '../../../hooks/useAuth';
import { useDispatch } from '../../../store';
import { Icon } from '@iconify/react/dist/iconify.js';

function AuthForgetPassword(props) {
    const theme = useTheme()
    const { resetPassword } = useAuth();
    const dispatch = useDispatch();

    const navigate = useNavigate()
    //for input
    const [values, setValues] = useState({ email: '' })
    const [errors, setErrors] = useState({ email: '' })

    //for success/error message
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
        setErrors({ ...errors, [e.target.name]: '' })
    }

    const showMessage = (type, message) => {
        if (type === 'error') {
            setError(message)
            setTimeout(() => {
                setError('')
            }, 3000)
        }
        else if (type === 'success') {
            setSuccess(message)
            setTimeout(() => {
                setSuccess('')
            }, 3000)
        }
    }


    const handleSubmit = async () => {
        const { email } = values;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!email) {
            setErrors({ ...errors, email: "Email is required" })
            return
        }
        if (!emailRegex.test(email)) {
            setErrors({ ...errors, email: "Email is not valid" })
            return
        }

        setIsSubmitting(true)

        try {
            await resetPassword(email)
                .then((res) => {
                    showMessage('success', "Password reset link sent successfully.")
                })
        }
        catch (error) {
            showMessage('error', (error?.message || "Something went wrong"))
        }
        finally {
            setIsSubmitting(false)
        }
    }

    return (
        <>
            <div
                style={{
                    color: theme.palette.text.dark
                }}
                className='flex flex-col items-start w-full'
            >
                <h2 className='text-xl sm:text-2xl font-semibold text-primary'>
                    Enter your email to reset <br />password
                </h2>
            </div>

            <div className={`${LoginStyles.form}`}>
                <div className='flex flex-col gap-4'>
                    <FormControl fullWidth error={Boolean(errors.email)}>
                        <OutlinedInput
                            placeholder='Email Address'
                            type="email"
                            value={values.email}
                            name="email"
                            onChange={(event) => handleChange(event)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Icon icon="carbon:email" className='text-[20px]' />
                                </InputAdornment>
                            }
                            inputProps={{ className: Styles.input }}
                        />

                        {errors.email && (
                            <FormHelperText className={LoginStyles.error_message} id="standard-weight-helper-text-email-login">
                                {errors.email}
                            </FormHelperText>
                        )}
                    </FormControl>

                </div>

                <Box sx={{ mt: 3 }}>
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        style={{
                            padding: '12px 0px',
                            borderRadius: '10px'
                        }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </LoadingButton>
                </Box>

                {error ?
                    <div className={`${LoginStyles.error_box} p-3 mt-3`}>
                        {error}
                    </div>
                    :
                    success ?
                        <div className={`${LoginStyles.success_box} p-3 mt-3`}>
                            {success}
                        </div>
                        :
                        <></>
                }
            </div>

        </>
    )
}

export default AuthForgetPassword
