import React, { useMemo, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Breadcrumbs, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Header from './Header';
import HorizontalBar from './HorizontalBar';
import Sidebar from './Sidebar';
import LAYOUT_CONST from '../../constant';
import useConfig from '../../hooks/useConfig';
import { drawerWidth } from '../../store/constant';
import { openDrawer } from '../../store/slices/menu';
import { useDispatch, useSelector } from '../../store';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter + 200
        }),
        minHeight: `calc(100vh - ${0}px)`,
        [theme.breakpoints.up('md')]: {
            marginLeft: layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - 72) : '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            marginTop: layout === 'horizontal' ? 135 : 0,
        },
        [theme.breakpoints.down('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginTop: 50,

            paddingBottom: '60px',
            height: `calc(100vh - 100px)`,
            overflowY: 'scroll',
        },
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginRight: '0px',
            marginTop: 50,
            paddingBottom: '60px',
            height: `calc(100vh - 100px)`,
            overflowY: 'scroll',
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter + 200
        }),
        marginLeft: layout === 'horizontal' ? '20px' : 0,
        marginTop: layout === 'horizontal' ? 135 : 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        minHeight: `calc(100vh - ${0}px)`,
        [theme.breakpoints.up('md')]: {
            marginTop: layout === 'horizontal' ? 135 : 0
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 50
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 50
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container, drawerType, layout } = useConfig();

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER && !matchDownMd) {
            dispatch(openDrawer(true));
        }
        else {
            dispatch(openDrawer(false));
        }
    }, [drawerType]);


    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

    const header = useMemo(
        () => (
            <Toolbar
                sx={{
                    pl: condition ? '10px' : '16px',
                    paddingRight: '0px !important',
                    width: 'fit-content'
                }}
            >
                <Header />
            </Toolbar>
        ),
        [layout, matchDownMd]
    );


    return (
        <Box sx={{ display: 'flex', }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    bgcolor: 'background.default',
                    transition: drawerOpen ? theme.transitions.create('width') : 'none',
                    height: matchDownMd ? '50px' : '0px',
                    borderBottom: `1px solid ${theme.palette.border.main}`
                }}
            >
                {header}
            </AppBar>

            {/* horizontal menu-list bar */}
            {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && <HorizontalBar />}

            {/* drawer */}
            {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && <Sidebar />}

            {/* main content */}
            <Main theme={theme} open={drawerOpen} layout={layout}>
                <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
                    <Outlet />
                </Container>
            </Main>
        </Box>
    );
};

export default MainLayout;
