import logoDark from '../assets/images/logoDark.png'
import logoLight from '../assets/images/logo.png'
import { useTheme } from '@mui/material/styles'

export const Logo = ({ containerStyle, textStyle, imgStyle }) => {
    const theme = useTheme();
    const mode = theme.palette.mode;

    return (
        <div
            className="flex items-center gap-1"
            style={{
                ...containerStyle
            }}
        >
            <img
                src={logoDark}
                alt="logo"
                style={{
                    height: '40px',
                    width: '40px',
                    ...imgStyle
                }}
            />
            <h2
                className='text-xl sm:text-2xl font-semibold text-white'
                style={{
                    lineHeight: '40px',
                    ...textStyle
                }}
            >
                Ring<span className="text-primary">Us</span>
            </h2>

        </div>

    )
}