// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import UserReducer from './slices/user';
import menuReducer from './slices/menu';
import confirmationModal from './slices/confirmationModal';
import detailsDrawer from './slices/detailsDrawer';
import snackbar from './slices/snackbar';
import phoneNumbers from './slices/phoneNumbers';
import agent from './slices/agent';
import userTestNumbers from './slices/userTestNumbers';
import support from './slices/support';
import payment from './slices/payment';
import subscription from './slices/subscription';
import session from './slices/session';
import apiKey from './slices/apiKey';
import overlayLoading from './slices/overlayLoading';
import personalAssistant from './slices/personalAssistant';
import callHistory from './slices/callHistory';
import chatBot from './slices/chatBot';
import outboundCall from './slices/outboundCall';
import organization from './slices/organization';
import accountReducer from './accountReducer';
import liveAiConversation from './slices/liveAiConversation';
import onboardingTour from './slices/onboardingTour'
import dashboard from './slices/dashboard'

// Admin slices
import adminPhoneNumbers from './slices/Admin/phoneNumber';
import adminTakenNumbers from './slices/Admin/takenNumber';
import adminAgentTemplate from './slices/Admin/agentTemplate';
import adminAgent from './slices/Admin/agent';
import adminOrganization from './slices/Admin/Organization';
import adminOrgMember from './slices/Admin/orgMember';
import adminUser from './slices/Admin/user';
import adminMiscellaneous from './slices/Admin/miscellaneous';
import adminOrgAgentTemplate from './slices/Admin/orgAgentTemplate';


// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    menu: menuReducer,
    user: UserReducer,
    phoneNumbers,
    testPhoneNumber: userTestNumbers,
    agent,
    support,
    payment,
    subscription,
    confirmationModal,
    detailsDrawer,
    overlayLoading,
    snackbar,
    apiKey,
    session,
    personalAssistant,
    chatBot,
    callHistory,
    outboundCall,
    organization,
    account: accountReducer,
    liveAiConversation,
    onboardingTour,
    dashboard,

    // admin
    adminPhoneNumbers,
    adminTakenNumbers,
    adminAgentTemplate,
    adminAgent,
    adminOrganization,
    adminOrgMember,
    adminUser,
    adminMiscellaneous,
    adminOrgAgentTemplate
});

export default reducer;
