
/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../../utils/axios.js';
import { dispatch } from '../../index';
import { handleError, handleSuccess } from '../../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    orgMembers: null,
    singleOrgMember: null,
    loading: false,
    saveLoading: false,
    totalPages: null,
    totalCount: null,
};

const slice = createSlice({
    name: 'adminOrgMember',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isSaveLoading(state, action) {
            state.saveLoading = action.payload;
        },

        setOrgMemberDataSuccess(state, action) {
            state.orgMembers = action.payload?.data;
            state.totalCount = action.payload?.totalCount;
            state.totalPages = action.payload?.totalPages;
        },

        setSingleOrgMemberDataSuccess(state, action) {
            state.singleOrgMember = action.payload;
        },

        updateOrgMemberDataSuccess(state, action) {
            const index = state.orgMembers.findIndex(orgMember => orgMember._id === action.payload.id);
            const orgMemberData = state.orgMembers[index];
            const newData = { ...orgMemberData, ...action.payload.data };
            state.orgMembers[index] = newData
        },

        createOrgMemberDataSuccess(state, action) {
            state.orgMembers.push(action.payload);
        },

        deleteOrgMemberDataSuccess(state, action) {
            const newOrgMembers = state.orgMembers.filter(orgMember => orgMember._id !== action.payload.id);
            state.orgMembers = newOrgMembers;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllOrgMembers({ page, limit, search }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/organization-member?search=${search}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setOrgMemberDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}
export function getSingleOrgMember({ id }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/admin/organization-member/${id}`);
            dispatch(slice.actions.setSingleOrgMemberDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}

export function createOrgMember(data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.post(`/admin/organization-member`, data);
            dispatch(slice.actions.createOrgMemberDataSuccess(response.data))

            handleSuccess(response, "OrgMember created successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function updateOrgMember(id, data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.put(`/admin/organization-member/${id}`, data);

            dispatch(slice.actions.updateOrgMemberDataSuccess(
                { data, id }
            ))
            handleSuccess(response, "OrgMember updated successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function deleteOrgMember(id) {
    return async () => {
        try {
            const response = await axios.delete(`/admin/organization-member/${id}`);
            dispatch(slice.actions.deleteOrgMemberDataSuccess(
                { id }
            ))
            handleSuccess(response, "OrgMember deleted successfully", dispatch);

            return response.data?.message;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
    }
}
