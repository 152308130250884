import { act, memo, useEffect, useMemo, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Drawer, FormControl, MenuItem, Modal, Popover, Select, Stack, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from '../MenuList';
import LogoSection from '../LogoSection';
import MiniDrawerStyled from './MiniDrawerStyled';

import LAYOUT_CONST from '../../../constant';
import useConfig from '../../../hooks/useConfig';
import { drawerWidth } from '../../../store/constant';

import { useDispatch, useSelector } from '../../../store';
import { openDrawer } from '../../../store/slices/menu';
import { useNavigate } from 'react-router-dom';
import ProfileSection from '../Header/ProfileSection'
import Organization from '../Header/ProfileSection/organizationTwo';
import clsx from 'clsx';
// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
    const theme = useTheme();
    const palette = theme.palette;
    const mode = palette.mode

    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate()

    const { loading: orgs_loading, organizations } = useSelector(state => state.organization)

    const { active_org, data: userData } = useSelector(state => state.account)
    const level = 1

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const { layout, drawerType } = useConfig();

    const drawerSX = {
        paddingLeft: drawerOpen ? '16px' : 0,
        paddingRight: drawerOpen ? '16px' : 0,
        marginTop: drawerOpen ? '15px' : '0px'
    };

    const logo = useMemo(
        () => (
            <div className={`flex items-center gap-1`}>
                <LogoSection />
                {(drawerOpen || (!drawerOpen && level !== 1)) && (
                    <h1 className='text-2xl font-bold'>
                        Ring
                        <span style={{ color: palette.primary.main }}>
                            Us
                        </span>
                    </h1>
                )}
            </div>
        ),
        [matchUpMd, drawerOpen, drawerType]
    );

    const organization = useMemo(
        () => (
            <>
                <Organization
                    organizations={organizations}
                    active_org={active_org}
                    collapsed={!drawerOpen}
                />
            </>
        ),
        [matchUpMd, drawerOpen, drawerType, active_org]
    );

    const drawerContent = (
        <>
            <MenuList />
        </>
    );

    const drawer = useMemo(
        () => (
            <>
                {drawerContent}
            </>
        ),
        [matchUpMd, drawerOpen, drawerType]
    );

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            {matchDownMd || (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
                <Drawer
                    variant={matchUpMd ? 'persistent' : 'temporary'}
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                        '& .MuiDrawer-paper': {
                            mt: matchDownMd ? 0 : 11,
                            zIndex: 1099,
                            width: drawerWidth,
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: `1px solid ${theme.palette.border.main} !important`
                        },
                        borderRight: `1px solid ${theme.palette.border.main} !important`
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    <div
                        style={{
                            paddingTop: '20px',
                            paddingLeft: drawerOpen ? '24px' : '10px',
                            paddingRight: drawerOpen ? '20px' : '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: drawerOpen ? '20px' : '12px',
                                width: '100%',
                            }}
                        >
                            {matchDownMd && logo}
                            <div
                                className='relative'
                                style={{
                                    margin: !drawerOpen && '0px -8px',
                                }}
                            >
                                {organization}
                            </div>

                            <div
                                style={{
                                    margin: drawerOpen ? '0px -10px' : '0px -14px'
                                }}
                            >
                                {drawer}
                            </div>
                        </div>

                        <div
                            className='relative'
                            style={{
                                margin: drawerOpen ? '0px -10px' : '0px -14px'
                            }}
                        >
                            <ProfileSection
                                drawerOpen={drawerOpen}
                                level={level}
                            />
                        </div>
                    </div>
                </Drawer>
            ) : (
                <MiniDrawerStyled variant="permanent" open={drawerOpen}>
                    <div
                        style={{
                            paddingTop: '20px',
                            paddingLeft: drawerOpen ? '24px' : '10px',
                            paddingRight: drawerOpen ? '20px' : '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: drawerOpen ? '20px' : '12px',
                                width: '100%',
                            }}
                        >
                            {logo}
                            <div
                                className='relative'
                                style={{
                                    margin: !drawerOpen && '0px -8px',
                                }}
                            >
                                {organization}
                            </div>

                            <div
                                style={{
                                    margin: drawerOpen ? '0px -10px' : '0px -14px'
                                }}
                            >
                                {drawer}
                            </div>
                        </div>

                        <div
                            className='relative'
                            style={{
                                margin: drawerOpen ? '0px -10px' : '0px -14px'
                            }}
                        >
                            <ProfileSection
                                drawerOpen={drawerOpen}
                                level={level}
                            />
                        </div>
                    </div>

                </MiniDrawerStyled>
            )}
        </Box>
    );
};

export default memo(Sidebar);
